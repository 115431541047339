var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',[(_vm.data.label && _vm.data.label !== '')?_c('Label',{attrs:{"data":{
      value: _vm.data.label,
      tag: _vm.data.tag_label,
      size: _vm.data.size_label,
    }}}):_vm._e(),(_vm.data.image?.default)?_c('Spacer',{attrs:{"bottom":"m"}},[_c('Figure',{attrs:{"data":{ ..._vm.data.image, ratio: true, lazyload: true }}})],1):_vm._e(),(_vm.data.title && _vm.data.title !== '')?_c('Spacer',{attrs:{"bottom":"s"}},[(_vm.data.title && _vm.data.title !== '')?_c('Title',{attrs:{"data":{
        value: _vm.data.title,
        tag: _vm.data.tag_title,
        size: _vm.data.size_title,
      }}}):_vm._e()],1):_vm._e(),(_vm.data.rich_text && _vm.data.rich_text !== '')?_c('RichText',{attrs:{"data":{
      value: _vm.data.rich_text,
      size: _vm.data.size_rich_text,
    }}}):_vm._e(),_c('div',_vm._l((_vm.data.ctas),function(item,index){return _c('Cta',{key:index,attrs:{"data":{ value: item.cta },"theme":_vm.data.cta_layout}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }